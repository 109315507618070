import React from 'react'
import { graphql } from 'gatsby'
import type { MeasuresPageProps } from 'src/views/sizeguide/measuresPage'
import MeasuresPage from 'src/views/sizeguide/measuresPage'
import GatsbySeoCustom from 'src/components/GatsbySeoCustom'

function Page({ location }: MeasuresPageProps) {
  return (
    <>
      <GatsbySeoCustom
        title="Size Guide"
        description="Size Guide Component"
        language="pt-BR"
        noindex
        nofollow
      />
      <MeasuresPage location={location} />
    </>
  )
}

export const query = graphql`
  query MeasuresPageQuery {
    cmsGlobalComponents {
      sections {
        name
        data
      }
    }
  }
`

export default Page
